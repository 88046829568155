import { Plugin } from '@nuxt/types'
import AuthService from '../components/Services/AuthService'
import WindowService from '~/components/Services/WindowService'
import ProductService from '~/components/Services/ProductService'
import ProposalService from '~/components/Services/ProposalService'
import DayService from '~/components/Services/DayService'
import PageService from '~/components/Services/PageService'
import UserService from '~/components/Services/UserService'
import ClientProductService from '~/components/Services/ClientProductService'
import MessageService from '~/components/Services/MessageService'
import MessageAccountService from '~/components/Services/MessageAccountService'
import KakutokuService from '~/components/Services/KakutokuService'
import MemberService from '~/components/Services/MemberService'
import ConnectService from '~/components/Services/ConnectService'
import LoginService from '~/components/Services/LoginService'
import CloudService from '~/components/Services/CloudService'
import ConnectCompanyService from '~/components/Services/ConnectCompanyService'
import MessagePeriodicCheckService from '~/components/Services/MessagePeriodicCheckService'

export interface IServices {
  auth: AuthService
  window: WindowService
  day: DayService
  user: UserService
  page: PageService

  product: ProductService
  proposal: ProposalService
  client_product: ClientProductService
  message: MessageService
  messageAccount: MessageAccountService
  kakutoku: KakutokuService
  member: MemberService
  connect: ConnectService
  login: LoginService
  cloud: CloudService
  connectCompany: ConnectCompanyService

  messagePeriodicCheckService: MessagePeriodicCheckService
}

declare module 'vue/types/vue' {
  interface Vue {
    readonly $services: IServices
  }
}

declare module '@nuxt/types' {
  interface Context {
    readonly $services: IServices
  }
}

declare module 'vuex/types/index' {
  interface Store<S> {
    readonly $services: IServices
  }
}

const servicesPlugin: Plugin = (context, inject) => {
  const services = {
    auth: new AuthService(context.$auth),
    window: new WindowService(),
    // @ts-ignore
    day: new DayService(context.$dayjs),
    user: new UserService(context.$auth),
    page: new PageService(undefined),
    product: new ProductService(context.$axios),
    proposal: new ProposalService(context.$axios),
    client_product: new ClientProductService(context.$axios),
    message: new MessageService(context.$axios),
    messageAccount: new MessageAccountService(),
    kakutoku: new KakutokuService(context.$axios),
    member: new MemberService(context.$axios),
    connect: new ConnectService(context.$axios, context.$config),
    login: new LoginService(context.$axios, context.$config),
    cloud: new CloudService(context.$axios, context.$config),
    connectCompany: new ConnectCompanyService(context.$axios),
    messagePeriodicCheckService: new MessagePeriodicCheckService(context),
  }
  inject('services', services)
}

export default servicesPlugin
