import {Dayjs} from "dayjs";
import 'dayjs/locale/ja'

export default class DayService {
  private readonly dayjs: (time: string|void) => Dayjs;

  constructor(dayjs: (time: string|void) => Dayjs) {
    this.dayjs = dayjs
  }

  /**
   * MM/DD(dd)
   * @param time
   */
  public getMdWithDayOfWeek(time: string): string
  {
    const dayjs = this.dayjs(time).locale('ja')

    return dayjs.format('MM/DD(dd)')
  }

  /**
   * YYYY/M/D
   * @param time
   */
  public getYmd(time: string): string
  {
    const dayjs = this.dayjs(time)

    return dayjs.format('YYYY/M/D')
  }

  /**
   * YYYY/MM/DD
   * @param time
   */
  public getYmmdd(time: string): string
  {
    const dayjs = this.dayjs(time)

    return dayjs.format('YYYY/MM/DD')
  }

  /**
   * YYYY年MM月DD日
   * @param time
   */
  public getYmdJa(time: string): string
  {
    const dayjs = this.dayjs(time)

    return dayjs.format('YYYY年MM月DD日')
  }

  /**
   * 過ぎたかどうか
   * @param time
   */
  public expired(time: string): boolean
  {
    return this.dayjs().isAfter(time)
  }

  public getY(time: string): string
  {
    const dayjs = this.dayjs(time)

    return dayjs.format('YYYY')
  }

  public getM(time: string): string
  {
    const dayjs = this.dayjs(time)

    return dayjs.format('M')
  }
}
