import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import IThreadDetail from "../Interfaces/Message/IThreadDetail";

@Module({ stateFactory: true, namespaced: true, name: 'ThreadDetails'})
export default class ThreadDetails extends VuexModule {
  details: IThreadDetail[] = []

  @Mutation
  setThreadDetails(details: IThreadDetail[]|undefined)
  {
    if(details === undefined) {
      return
    }

    this.details = details;
  }

  @Mutation
  clearThreadDetails() {
    this.details = []
  }

  @Mutation
  addThreadDetail(details: IThreadDetail[]) {
    const uniqueDetails = details.filter((detail) => {
      return (
        this.details.findIndex(
          (d: IThreadDetail) => d.threadDetailId === detail.threadDetailId
        ) === -1
      )
    })

    this.details = this.details.concat(uniqueDetails)
  }

  get getThreadDetails(): IThreadDetail[] {
    return this.details
  }
}
